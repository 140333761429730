import React from 'react';
import { makeStyles, Grid, Popover, Button } from '@material-ui/core';

import CallIcon from 'src/assets/icons/main_menu/hepl_call_icon.svg';
import ChatIcon from 'src/assets/icons/main_menu/hepl_chat_icon.svg';
import KnowledgeIcon from 'src/assets/icons/main_menu/hepl_knowledge_icon2.svg';
import MeetIcon from 'src/assets/icons/main_menu/meet.svg';

const useStyles = makeStyles(theme => ({
  popupWrapper: {
    textAlign: 'center',
    margin: '30px 20px',
  },
  popupTitle: {
    fontFamily: 'Lato',
    marginBottom: '26px',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
  },
  popupButton: {
    display: 'block',
    maxWidth: '33%',
    fontFamily: 'Lato',
  },
  popupText: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#2A97B7',
  },
  popupMenuWrapper: {
    justifyContent: 'space-between',
    width: '484px',
  }
}));

export default function HelpWidget({ isOpen, popupList, anchorEl, handleClosePopup }) {
  const classes = useStyles();

  const navigateToPage = page => {
    if (!page.onClickMethod) {
      navigate(`/me${page.link}`, { state: { name: page.name } });
      return;
    }

    switch (page.onClickMethod) {
      case 'href':
        const { url, target } = page.link;
        window.open(url, target);
        break;
      case 'function':
        switch (page.link) {
          case 'openHelpChat':
            openHelpChat();
            break;
        }
        break;
    }
  };

  const openHelpChat = () => {
    const elements = document.getElementById('customerly-container').getElementsByTagName('iframe');

    if (elements.length < 1) {
      return;
    }

    elements[0].contentWindow.document.getElementsByTagName("button")[0].click();
  };

  return (
    <Popover
      id='popup-p'
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClosePopup}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Grid className={classes.popupWrapper}>
        <Grid className={classes.popupTitle}>
          Get Help Now
        </Grid>

        <Grid container className={classes.popupMenuWrapper}>
          {popupList.map(ele => (
            <Button
              className={classes.popupButton}
              key={ele.name}
              onClick={() => {
                navigateToPage(ele);
              }}
            >
              {
                ele?.type === 1 && (<CallIcon />)
                || ele?.type === 2 && (<ChatIcon />)
                || ele?.type === 3 && (<KnowledgeIcon />)
                || ele?.type === 4 && (<MeetIcon />)
              }

              <br />

              <span className={classes.popupText}>
                {ele.name}
              </span>
            </Button>
          ))}
        </Grid>
      </Grid>
    </Popover>
  );
}
