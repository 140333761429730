/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles, AppBar, Toolbar, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  toolbar: {
    marginLeft: 'auto',
    padding: theme.spacing(8, 8, 5),
  },
  link: {
    cursor: 'pointer',
  },
}));

export default function Header() {
  const classes = useStyles();

  const openHelpChat = () => {
    const elements = document.getElementById('customerly-container').getElementsByTagName('iframe');

    if (elements.length < 1) {
      return;
    }

    elements[0].contentWindow.document.getElementsByTagName("button")[0].click();
  }

  return (
    <AppBar position="relative" color="transparent" elevation={0}>
      {/* <Toolbar /> */}
      <Toolbar className={classes.toolbar}>
        <Typography variant="h5" noWrap>
          Having troubles? <Link className={classes.link} onClick={openHelpChat}>Get help</Link>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
